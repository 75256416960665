var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "action-collapse": "",
            "show-loading": _vm.busy,
            title: _vm.$t("Atendimento"),
          },
        },
        [
          _c(
            "FormulateForm",
            { ref: "form", on: { submit: _vm.saveServiceRequest } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "service-area",
                          label: _vm.$t("Area"),
                          type: "vue-select",
                          options: _vm.serviceAreaOptions,
                          validation: "required",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.localFetchServiceCategory()
                          },
                        },
                        model: {
                          value: _vm.serviceRequest.serviceAreaId,
                          callback: function ($$v) {
                            _vm.$set(_vm.serviceRequest, "serviceAreaId", $$v)
                          },
                          expression: "serviceRequest.serviceAreaId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "service-category",
                          label: _vm.$t("Categoria"),
                          type: "vue-select",
                          disabled: !_vm.serviceRequest.serviceAreaId,
                          options: _vm.serviceCategoryOptions,
                          validation: "required",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.serviceCategorySelected()
                          },
                        },
                        model: {
                          value: _vm.serviceRequest.serviceCategoryId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.serviceRequest,
                              "serviceCategoryId",
                              $$v
                            )
                          },
                          expression: "serviceRequest.serviceCategoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _vm.isRequestedForUser
                        ? _c("e-user-search", {
                            attrs: {
                              id: "user_id",
                              label: _vm.$t("Para quem?"),
                              required: _vm.isRequestedForUser,
                              name: "user_id",
                            },
                            model: {
                              value: _vm.serviceRequest.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.serviceRequest, "userId", $$v)
                              },
                              expression: "serviceRequest.userId",
                            },
                          })
                        : _c("e-store-combo", {
                            attrs: {
                              required: !_vm.isRequestedForUser,
                              "only-active-options": "",
                            },
                            model: {
                              value: _vm.serviceRequest.storeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.serviceRequest, "storeId", $$v)
                              },
                              expression: "serviceRequest.storeId",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "service-description",
                          label: _vm.$t("Descrição"),
                          type: "textarea",
                          rows: "20",
                          validation: "required",
                        },
                        model: {
                          value: _vm.serviceRequest.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.serviceRequest, "description", $$v)
                          },
                          expression: "serviceRequest.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "show-loading": _vm.busy,
                    "action-collapse": "",
                    title: _vm.$t("Anexos"),
                  },
                },
                [
                  _c("files-form", {
                    attrs: { files: _vm.files, "is-read-only": false },
                    on: {
                      "set-files": _vm.setFiles,
                      "remove-file": _vm.removeFile,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { busy: _vm.busy },
        on: {
          save: _vm.saveServiceRequest,
          cancel: function ($event) {
            return _vm.$router.back()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }